require('../scss/app.scss');
require('../fonts/Helvetica-bd/fonts.css');
require('../fonts/HelveticaNeueCyr/css/fonts.css');
require('../fonts/Helvetica-Th/fonts.css');

window.noZensmooth = true;

require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');
require('./common/menu.js');
require('./common/_initTabs.js');

require('./common/shops.js');
require('./common/script.js');

// Зависимости
import "@fortawesome/fontawesome-free/js/all";
require('./common/_swiper');

