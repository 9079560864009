import LiveEvent from "../../components/live/live";
import zenscroll from "zenscroll";

/**
 * Created by MooWPro on 13/11/2017.
 */
var placemarks = [];
var geocollection = {};

ymaps.ready(
    function getSelector() {
        const selector = document.getElementById('map');

        if(selector) init(selector)

        function init(selector) {

            var myMap = new ymaps.Map(selector, {
                center: [55.752411, 37.609407],
                zoom: 10,
                controls: ['fullscreenControl','zoomControl']
            }, {
                searchControlProvider: 'yandex#search'
            });

            myMap.behaviors.disable('scrollZoom');

            const select = document.querySelector('.shops-city-select')

            // on select change
            select.addEventListener('change', () => {
                const slug = select.querySelector(`option[value='${select.value}']`).dataset.slug
                window.location.href=`/store/${slug}`;
            })

            function createMarkByCityId(){
                // return value selected option as number
                const id = select.selectedOptions[0].value

                placemarks = [];
                for (var i = 0, l = groups.length; i < l; i++) {
                    if(groups[i].id == id) {
                        createGroup(groups[i]);
                    }
                }
            }
            createMarkByCityId();

            function createGroup (group) {
                geocollection = new ymaps.GeoObjectCollection(null, { preset: 'islands#blueIcon' });
                myMap.geoObjects.add(geocollection);
                for (var j = 0, m = group.items.length; j < m; j++) {
                    var placemark = new ymaps.Placemark(group.items[j].center, { balloonContent: group.items[j].name });
                    geocollection.add(placemark);
                    placemarks.push({id: group.items[j].id, mark: placemark});
                }
            }

            if(groups[0].items.length <= 1){
                myMap.setCenter([groups[0].items[0].center[0], groups[0].items[0].center[1]],10);
            }else{
                myMap.setBounds(myMap.geoObjects.getBounds());
            }
        }

        new LiveEvent('click', '[data-balloon-link]', function shopScrollBalloon(e) {
            e.preventDefault();
            var shop_id = $(this).data('shop-id');
            for (var i = 0, l = placemarks.length; i < l; i++) {
                if(placemarks[i].id == shop_id) {
                    placemarks[i].mark.balloon.open();
                }
            }

            let offset = this.dataset.offset || 0;
            if (selector) {
                let viewportOffset = selector.getBoundingClientRect();
                let top = (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0);
                zenscroll.toY(top + viewportOffset.top + offset, 500);
            }

            return false;
        })
    }
);
