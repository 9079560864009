function initheader(maxWidthClose) {
    const header = document.querySelector(".header__column_logo");
    const headerList = document.querySelector(".header__ul");
    const headerBurger = document.querySelector(".header__burger");
    const body = document.body;
    const subLi = document.querySelectorAll('.header__sub-li')
    headerBurger.addEventListener("click", () => {
        body.classList.toggle("lock");
        header.classList.toggle("header__column_logo_active");
        headerList.classList.toggle("header__ul_active");
        headerBurger.classList.toggle("header__burger_active");

        closeMenuOnLinkClick();
    });

    if(maxWidthClose) {
    window.addEventListener("resize", () => {
            const w = document.documentElement.clientWidth;
            if (w > maxWidthClose) {
                close();
            }
        });
    }

    function close() {
        body.classList.remove("lock");
        header.classList.remove("header__column_logo_active");
        headerList.classList.remove("header__ul_active");
        headerBurger.classList.remove("header__burger_active");
    }

    function closeMenuOnLinkClick() {
        subLi.forEach((el) => {
            el.addEventListener('click', close)
        })
    }
}
initheader(599)