// js для свайпера, подключается блоками
// подробнее тут <https://swiperjs.com/get-started>
import SwiperCore, { Swiper, Navigation, Pagination, Autoplay} from "swiper/core";
SwiperCore.use([Swiper, Navigation, Pagination, Autoplay]);

// стили для свайпера
// брать тут <https://swiperjs.com/swiper-api#styles>, только для нужных компонентов
import "swiper/swiper.min.css";
// import "swiper/components/pagination/pagination.min.css";
// import "swiper/components/navigation/navigation.min.css";

window.addEventListener("DOMContentLoaded", () => {
    if (document.querySelector(".swiper-container-main")) {
        new Swiper(".swiper-container-main", {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,
            spaceBetween: 30,

            watchOverflow: true,
            centerInsufficientSlides: true,
            // Мобайл-first подход (min-width: ...)

            // // ========================================================
            // Включить предзагрузку изображений
            preloadImages: false,
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: true,
            },

            // Слежка за видимыми слайдами
            watchSlidesProgress: true,
            // Добавление класса видимым сладам
            watchSlidesVisibility: true,

            // Скорость переключения слайдов
            speed: 1500,

            // Автопрокрутка
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
                // stopOnLastSlide: true,
            },
        });
    }
    if (document.querySelector(".swiper-container-product")) {
        new Swiper(".swiper-container-product", {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,
            spaceBetween: 30,

            watchOverflow: true,
            centerInsufficientSlides: true,
            // Мобайл-first подход (min-width: ...)
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                600: {
                    slidesPerView: 2,
                },
                900: {
                    slidesPerView: 3,
                },
                1200: {
                    slidesPerView: 4,
                },
                1400: {
                    slidesPerView: 5,
                },
                1600: {
                    slidesPerView: 6,
                },
            },

            // // ========================================================
            // Включить предзагрузку изображений
            preloadImages: false,
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: true,
            },

            // Слежка за видимыми слайдами
            watchSlidesProgress: true,
            // Добавление класса видимым сладам
            watchSlidesVisibility: true,

            navigation: {
                prevEl: '.product__prev',
                nextEl: '.product__next',
            }
        });
    }
    if (document.querySelector(".swiper-container-shop")) {
        new Swiper(".swiper-container-shop", {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,
            spaceBetween: 30,

            watchOverflow: true,
            centerInsufficientSlides: true,
            // Мобайл-first подход (min-width: ...)
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                600: {
                    slidesPerView: 2,
                },
                900: {
                    slidesPerView: 3,
                },
            },

            // // ========================================================
            // Включить предзагрузку изображений
            preloadImages: false,
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: true,
            },

            // Слежка за видимыми слайдами
            watchSlidesProgress: true,
            // Добавление класса видимым сладам
            watchSlidesVisibility: true,

            navigation: {
                prevEl: '.shop__btn_prev',
                nextEl: '.shop__btn_next',
            }
        });
    }
    if (document.querySelector(".swiper-container-big-slider")) {
        new Swiper(".swiper-container-big-slider", {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,
            spaceBetween: 10,

            watchOverflow: true,
            centerInsufficientSlides: true,

            // // ========================================================
            // Включить предзагрузку изображений
            preloadImages: false,
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: true,
            },

            // Слежка за видимыми слайдами
            watchSlidesProgress: true,
            // Добавление класса видимым сладам
            watchSlidesVisibility: true,

            navigation: {
                prevEl: '.big-slider__prev',
                nextEl: '.big-slider__next',
            },

            pagination: {
                el: '.big-slider__pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + '</span>';
                },
              },


        });
    }
});
